import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";

@Injectable({
  providedIn: 'root',
})

export class LocationService {
    private logged:boolean;

    // public locationData = new BehaviorSubject<any>({});

    // setLocationInfo(data: any) {
    //     this.locationData.next(data);
    // }

    // getLocationInfo() {
    //     return this.locationData.asObservable();
    // }

    private locationData: Subject<any> = new Subject<any>();
    locationData$: Observable<any> = this.locationData.asObservable();
  
    constructor() { }
  
    setLocationInfo(updatedData) {
      this.locationData.next(updatedData);
    }    
    // getLocationInfo(query){
    //  this.locationData.subscribe(sharedData => {
    //   let getData = JSON.parse(sharedData)
    //   return getData[query] || ''
    //   });
     
    // }
}