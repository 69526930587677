import { Component, ElementRef } from "@angular/core";
import { AppSettings } from "../../app.config";
import { SectionService } from "./section.service";
import { ViewChild, ChangeDetectorRef, OnInit } from "@angular/core";
import { DataSharingService } from "../common/data-sharing.service";
import { Router } from "@angular/router";

let $: any;

@Component({
  selector: "sections",
  templateUrl: "./section.html",
  providers: [SectionService],
  styleUrls: ["./section.css"]
})
export class SectionComponent implements OnInit {
  RiderMenu: any = 0;
  DriverMenu: any = 0;

  customOptions: any = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ["&lt", "&gt"],
    nav: true
  };

  listVehiclesDynamically: any = [];

  defaultcurrency = AppSettings.defaultcur;
  defaultUnit = AppSettings.defaultUnit;
  showVehicleOffers = AppSettings.showWhatWeOffers;

  baseurl = AppSettings.BASEURL;
  list: any = {};
  ourDriverAry: any[] = [];

  constructor(
    private dataSharingService: DataSharingService,
    private router: Router,
    private sectionService: SectionService,
    private cdr: ChangeDetectorRef
  ) {
    dataSharingService.isUserLoggedIn.subscribe(value => {
      // this.hideButton();
    });
  }
  // hideButton() {
  //   if (localStorage.getItem("Tok") == null) {
  //     this.RiderMenu = 0;
  //     this.DriverMenu = 0;
  //     // window.location.href = this.fronturl+"/#/about";
  //   } else if (
  //     localStorage.getItem("Tok") != null &&
  //     localStorage.getItem("Type") === "rider"
  //   ) {
  //     this.RiderMenu = 1;
  //     this.DriverMenu = 0;
  //   } else if (
  //     localStorage.getItem("Tok") != null &&
  //     localStorage.getItem("Type") === "driver"
  //   ) {
  //     this.RiderMenu = 0;
  //     this.DriverMenu = 2;
  //   }
  // }

  call() {}

  ngOnInit(): void {
    this.GetHomPageDetail();
    this.sectionService.getOurDrivers().then(res => {
      this.ourDriverAry = res;
    });
    this.sectionService
      .listVehicles()
      .then(res => {
        this.listVehiclesDynamically = res;
        // console.log(this.listVehiclesDynamically);
      })
      .catch(res => {});
  }

  findRan(numb) {
    return Math.floor(Math.random() * numb + 1);
  }

  GetHomPageDetail(): void {
    this.sectionService.gethomecontent().then(res => {
      this.list = res[0];
    });
  }
}
