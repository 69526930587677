import { Component, OnInit } from "@angular/core";

// import {initial}from'./../../app.module';
import { Router } from "@angular/router";
import { HeaderService } from "./header.service";
import { AppSettings } from "../../app.config";
import { DataSharingService } from "../common/data-sharing.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "headsection",
  templateUrl: "./header.html",
  styleUrls: ['./header.component.css'],
  providers: [HeaderService]
  //   styles: [`
  //   header, hgroup, main, menu, nav, section, summary {
  //     display: block;
  //     background-color:  #f7a523;
  //     height: 49px;
  // }
  // .banner a, img {
  //   max-width: 92%;
  //   max-height: 66px;
  // }
  // .line-icon {
  //   cursor: pointer;
  //   float: left;
  //   margin: 35px 10px;
  //   background-color: black;
  // }
  // `]
})
export class HeaderComponent implements OnInit {
  items = [{ title: "Profile" }, { title: "Logout" }];

  menuclass: any;
  openoverly: any;
  Menu: any = 0;
  TopHeader: any;
  NavHeader: any;
  fronturl = AppSettings.FEURL;
  logoURL = AppSettings.LOGO_URL;
  logoWidth = AppSettings.LOGO_WIDTH;
  logoHeight = AppSettings.LOGO_HEIGHT;
  showServiceCity = AppSettings.showServiceCity;
  title = AppSettings.APPNAME;
  isUserLoggedIn: boolean;
  // userMenu = [
  //   { title: "Profile", icon: "fa fa-user" },
  //   { title: "Settings", icon: "fa fa-gear" },
  //   { title: "Log out", icon: "fa fa-sign-out" }
  // ];

  constructor(
    private headerService: HeaderService,
    private dataSharingService: DataSharingService,
    private router: Router,
    private translate: TranslateService
  ) {
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang);

    this.dataSharingService.isUserLoggedIn.subscribe(value => {
      this.isUserLoggedIn = value;
      if (localStorage.getItem("Tok") == null) {
        this.Menu = 0;
      } else if (
        localStorage.getItem("Tok") != null &&
        localStorage.getItem("Type") === "driver"
      ) {
        this.Menu = 1;
      } else if (
        localStorage.getItem("Tok") != null &&
        localStorage.getItem("Type") === "company"
      ) {
        this.Menu = 2;
      } else if (
        localStorage.getItem("Tok") != null &&
        localStorage.getItem("Type") === "rider"
      ) {
        this.Menu = 3;
      } else if (
        localStorage.getItem("Tok") != null &&
        localStorage.getItem("Type") === "restaurant"
      ) {
        this.Menu = 4;
      }
    });

    if (localStorage.getItem("Tok") == null) {
      this.Menu = 0;
    } else if (
      localStorage.getItem("Tok") != null &&
      localStorage.getItem("Type") === "driver"
    ) {
      this.Menu = 1;
    } else if (
      localStorage.getItem("Tok") != null &&
      localStorage.getItem("Type") === "company"
    ) {
      this.Menu = 2;
    } else if (
      localStorage.getItem("Tok") != null &&
      localStorage.getItem("Type") === "rider"
    ) {
      this.Menu = 3;
    } else if (
      localStorage.getItem("Tok") != null &&
      localStorage.getItem("Type") === "restaurant"
    ) {
      this.Menu = 4;
    }
    // this.ngOnInit();
  }

  MYLogout(): void {
    localStorage.clear();
    this.headerService.logout().then(msg => {
      this.dataSharingService.isUserLoggedIn.next(false);
      this.router.navigate(["/"]);
    });
  }

  menuoption(event) {
    this.ngOnInit();
    this.openoverly = !this.openoverly;
    if (event === 1) {
      this.menuclass = "sidbar-active";
      // this. ngOnInit();
    } else {
      this.menuclass = "";
      // this. ngOnInit();
    }
  }

  ngOnInit(): void {
    if (localStorage.getItem("Tok") == null) {
      this.Menu = 0;
      // window.location.href = this.fronturl+"/#/about";
    } else if (
      localStorage.getItem("Tok") != null &&
      localStorage.getItem("Type") === "driver"
    ) {
      this.Menu = 1;
    } else if (
      localStorage.getItem("Tok") != null &&
      localStorage.getItem("Type") === "company"
    ) {
      this.Menu = 2;
    } else if (
      localStorage.getItem("Tok") != null &&
      localStorage.getItem("Type") === "rider"
    ) {
      this.Menu = 3;
    } else if (
      localStorage.getItem("Tok") != null &&
      localStorage.getItem("Type") === "restaurant"
    ) {
      this.Menu = 4;
    }
  }
}
