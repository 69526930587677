import { Component, ViewChildren } from '@angular/core';
import { CommonService } from '../common/common.service';
import { ButtonToasterService } from '../buttontoaster/buttontoaster.service';
import { Router } from '@angular/router';

@Component({
  selector: "contact",
  templateUrl: './contact.html',
  styleUrls: ['./contact.component.css'],
  providers: [CommonService, ButtonToasterService]
})
export class ContactComponent {

  list: any = {};
  initial: number;

  constructor(private cservice: CommonService,
    private router: Router,
    private tost: ButtonToasterService) {
    this.initial = 0
    this.list = {};
  }
  contactform(){
    this.initial = 1
  }
  @ViewChildren('dataForm') form: any;

  SendTo(inputs) {
    if (!inputs) { return; }
    this.cservice.mailSend(inputs)
      .then(res => {
        this.tost.showtoast('success', res.message);
        this.list = {};
        this.router.navigate(['/']);
      })
      .catch(res => {
        this.tost.showtoast('error', res.message);
      })
  }
  goback(){
    this.list = {}
    this.initial = 0
  }
}
