import { Component } from "@angular/core";
import { CommonService } from "../common/common.service";

@Component({
  selector: "driverterms",
  templateUrl: "./driverterms.html",
  providers: [CommonService]
})
export class DriverTermsComponent {
  description: string;
  constructor(private cservice: CommonService) {
    this.cservice.getDriverTerms()
      .then(res => {
        console.log(res);
        this.description = res.doc;
        console.log(this.description);
      })
  }
}
