import { Component, ElementRef, HostListener } from "@angular/core";
import { PLATFORM_ID, APP_ID, Inject } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { LanguageSettings } from "./app.config";

@Component({
  selector: "app-root",
  templateUrl: "./index/main.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent {
  private headerElement: HTMLElement;
  private staticTranslateY = '0px'; // Default vertical translation (can be adjusted)
  headerTranslateY: string;
  constructor(
    private elementRef: ElementRef,
    public translate: TranslateService,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(APP_ID) private appId: string
  ) {
    translate.addLangs(LanguageSettings.languages);
    translate.setDefaultLang(LanguageSettings.defaultSelectedLang);
  }

  onActivate(event: any) {
    if (isPlatformBrowser(this.platformId)) {
      const scrollToTop = window.setInterval(() => {
        const pos = window.pageYOffset;
        if (pos > 0) {
          window.scrollTo(0, pos - 200); // how far to scroll on each step
        } else {
          window.clearInterval(scrollToTop);
        }
      }, 16);
    }
  }

  ngAfterViewInit(): void {
    this.headerElement = this.elementRef.nativeElement.querySelector('header');
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollY = window.pageYOffset;
    this.headerTranslateY = scrollY > 100 ? '-100%' : '0%';
  }

  getTransform(staticY: string, dynamicY: string): string {
    return `translate(-50%, ${dynamicY})`;
  }
}
