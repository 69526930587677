import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { DatePipe } from '@angular/common';
import { AppSettings } from '../../app.config';
import { MyTripService } from './trips.service';

@Component({
  selector: "trips",
  templateUrl: './trips.html',
  styleUrls: ['./trip.css'],
  providers: [DatePipe, MyTripService],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],

})

export class TripsComponent implements OnInit {

  makeary: any[] = [];
  modelary: any[] = [];
  yearary: any[] = [];
  companyary: any[] = [];
  driverary: any[] = [];
  vehicleary: any[] = [];
  private id;
  list: any = {};
  index = 1;
  columnsToDisplay = ['tripno', 'date', 'cpy', 'dvr', 'rid', 'fare', 'taxi', 'status', 'actions'];
  displayedColumns: string[] = ['tripno', 'date', 'fare', 'taxi', 'status', 'actions'];
  dataSource: any = [];
  filedata: any;
  expiryDate: any;
  selectedid: any;
  taxiMessage: string;
  singleTaxiId: any;
  baseurl: string = AppSettings.BASEURL;
  defaultCur = AppSettings.defaultcur;
  path: any;
  center = '0, -180';
  tripdetails: any[] = [];
  tripcspdetails: any[] = [];
  tripdspdetails: any;
  show = false;
  tabShow = true;
  defaultUnit = AppSettings.defaultUnit;
  defaultTime = AppSettings.defaultTime;

  constructor(private datePipe: DatePipe, private tService: MyTripService) {
    this.id = localStorage.getItem('Id');
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit() {
    this.tService.TripDetails().then(
      res => {
        // this.dataSource = res;
        this.dataSource = new MatTableDataSource(res);
        this.dataSource.paginator = this.paginator;
        //    console.log(res);
      });
  }

  goToInvoice(id) {
    // console.log(id);
    this.tripdetails = id;
    // this.tripdspdetails = id.dsp[0];
    // this.tripcspdetails = id.csp[0];
    // this.path = [
    //   { lat: parseFloat(this.tripdspdetails.pLat), lng: parseFloat(this.tripdspdetails.pLng) },
    //   { lat: parseFloat(this.tripdspdetails.dLat), lng: parseFloat(this.tripdspdetails.dLng) }
    // ];
    this.tripdspdetails = id.adsp;
    this.tripcspdetails = id.acsp;
    this.path = [
      { lat: parseFloat(this.tripdspdetails.pLat), lng: parseFloat(this.tripdspdetails.pLng) },
      { lat: parseFloat(this.tripdspdetails.dLat), lng: parseFloat(this.tripdspdetails.dLng) }
    ];
    this.show = true;
    this.tabShow = false;
    this.tripcspdetails['actualcost'] = this.convertAmount(this.tripcspdetails['actualcost']);
    this.tripcspdetails['base'] = this.convertAmount(this.tripcspdetails['base']);
    this.tripcspdetails['distfare'] = this.convertAmount(this.tripcspdetails['distfare']);
    this.tripcspdetails['timefare'] = this.convertAmount(this.tripcspdetails['timefare']);
    this.tripcspdetails['waitingCharge'] = this.convertAmount(this.tripcspdetails['waitingCharge']);
    this.tripcspdetails['detect'] = this.convertAmount(this.tripcspdetails['detect']);
    this.tripcspdetails['tax'] = this.convertAmount(this.tripcspdetails['tax']);
    this.tripcspdetails['cost'] = this.convertAmount(this.tripcspdetails['cost']);
  }

  convertAmount(data) {
    return parseFloat(data).toFixed(2);
  }

  goBack() {
    this.show = false;
    this.tabShow = true;
  }

}

