import { Component } from "@angular/core";
import { CommonService } from "../common/common.service";

@Component({
  selector: "riderterms",
  templateUrl: "./riderterms.html",
  providers: [CommonService]
})
export class RiderTermsComponent {
  description: string;
  constructor(private cservice: CommonService) {
    this.cservice.getRiderTerms()
      .then(res => {
        console.log(res);
        this.description = res.doc;
        console.log(this.description);
      })
  }
}
