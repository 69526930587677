import { APP_BASE_HREF } from '@angular/common';
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NguiMapModule } from '@ngui/map';
import { CarouselModule } from 'ngx-owl-carousel-o';
import {
  MatProgressSpinnerModule,
  MatMenuModule,
  MatIconModule,
  MatCardModule,
  MatToolbarModule,
  MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatSortModule,
  MatTableModule,
  MatCheckboxModule,
  MatPaginatorModule
} from '@angular/material';
import { NgModule, ReflectiveInjector, Injectable } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';
import { ButtonToasterService } from './index/buttontoaster/buttontoaster.service';
import { AppComponent } from './app.component';
import { HeaderComponent } from './index/header/header.component';
import { FooterComponent } from './index/footer/footer.component';
import { SectionComponent } from './index/section/section.component';
import { RouterModule, Routes, NoPreloading } from '@angular/router';
import { SigninComponent } from './index/signin/signin.component';
// import { DriverProfileComponent } from "./index/driverProfile/driverProfile.component";
import { HelpComponent } from './index/help/help.component';
import { AboutComponent } from './index/about /about.component';
import { ContactComponent } from './index/contact /contact.component';
import { DriversignupComponent } from './index/driversignup/driversignup.component';
import { HomesigninComponent } from './index/homesignin/homesignin.component';
import { RidersignupComponent } from './index/ridersignup/ridersignup.component';
import { RiderSigninComponent } from './index/ridersignin/ridersignin.component';
import { FaqComponent } from './index/faq/faq.component';
import { PrivacypolicyComponent } from './index/privacypolicy/privacypolicy.component';
import { TermsconditionComponent } from './index/termscondition/termscondition.component';
import { HowitworksComponent } from './index/howitworks/howitworks.component';
import { RiderTermsComponent } from './index/riderterms/riderterms.component';
import { DriverTermsComponent } from './index/driverterms/driverterms.component';
import { InsuranceComponent } from './index/insurance/insurance.component';
import { LegalComponent } from './index/legal/legal.component';
import { ProfileComponent } from './index/profile/profile.component';
import { FormsModule } from '@angular/forms';
// Import your library
import { OwlModule } from 'ngx-owl-carousel';
import { TaxiComponent } from './index/taxi/taxi.component';
import { TripsComponent } from './index/trips/trips.component';
import { EarningsComponent } from './index/earnings/earnings.component';
import { RiderTripsComponent } from './index/ridertrips/trips.component';
import { RiderProfileComponent } from './index/riderprofile/profile.component';
import { RiderWalletComponent } from './index/riderwallet/riderwallet.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { RiderForgotPassWordComponent } from './index/riderforgotpassword/riderForgotPassword.component';
import { DriverForgotPassWordComponent } from './index/driverForgotPassword/driverForgotPassword.component';
import { DashboardSectionComponent } from './index/dashboard-section/dashboard-section.component';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { of } from 'rxjs/observable/of';
import { concat } from 'rxjs/observable/concat';
import { mergeMap } from 'rxjs/operators';
import { AppSettings, LanguageSettings } from './app.config';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';

import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { AsyncPipe } from '../../node_modules/@angular/common';
import { NgxInputStarRatingModule } from 'ngx-input-star-rating';
import { ServicecitiesComponent } from './index/servicecities/servicecities.component';
import { NgxMaskModule } from 'ngx-mask';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FareEstimationPageComponent } from './index/fare-estimation-page/fare-estimation-page.component';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  const checkLang = LanguageSettings['fetchTranslateFilesFromAPI'] ? LanguageSettings['fetchTranslateFilesFromAPI'] : false;
  if (checkLang) {
    return new TranslateHttpLoader(httpClient, `${AppSettings.BASEURL}` + 'public/landingLanguageFile/', '.json');
  } else {
    return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
  }
}

const firebase = {
  // Rebustar

  // apiKey: 'AIzaSyD1-G7ns6yIfjIP7eASJf8C8uckfQOL1kI',
  // authDomain: 'rebustar-d96c1.firebaseapp.com',
  // databaseURL: 'https://rebustar-d96c1.firebaseio.com/',
  // projectId: 'rebustar-d96c1',
  // storageBucket: 'rebustar-d96c1.appspot.com',
  // messagingSenderId: '616628085451',
  // appId: '1:616628085451:android:faa1e2a467689c83'

  appName: 'sadim-3ce50',
  authDomain: 'sadim-3ce50.firebaseapp.com',
  databaseURL: 'https://sadim-3ce50.firebaseio.com/',
  storageBucket: 'sadim-3ce50.appspot.com'

  // Express Track

  // 'appName': 'backride-4ce41',
  // 'authDomain': 'backride-4ce41.firebaseapp.com',
  // 'databaseURL': 'https://backride-4ce41.firebaseio.com/',
  // 'storageBucket': 'backride-4ce41.appspot.com',

  // atoz

  // 'apiKey': 'AIzaSyDOk_bvA18lkJlilQxsXvB062v_xvFuUv8',
  // 'authDomain': 'atozcab-97481.firebaseapp.com',
  // 'databaseURL': 'https://atozcab-97481.firebaseio.com/',
  // 'project_id': 'atozcab-97481',
  // 'storageBucket': 'atozcab-97481.appspot.com',

  // backride

  // apiKey: 'AIzaSyAi0TnPkV4_VKbI8a4oeIu9aLjYh4Esvbw',
  // authDomain: 'backride-4ce41.firebaseapp.com',
  // databaseURL: 'https://backride-4ce41.firebaseio.com/',
  // projectId: 'backride-4ce41',
  // storageBucket: 'backride-4ce41.appspot.com',

  // MY Konos

  // apiKey: 'AIzaSyDbEJExP61vGuid7YUHUP278Fv0u6aT1zY',
  // authDomain: 'mykonos-97279.firebaseapp.com',
  // databaseURL: 'https://mykonos-97279.firebaseio.com/',
  // projectId: 'mykonos-97279',
  // storageBucket: 'mykonos-97279.appspot.com',
};

@Injectable()
export class SeoSettingsService {
  private apiUrl = AppSettings.API_ENDPOINT;

  getSEO(callback): void {
    fromPromise(fetch(this.apiUrl + 'seosettings/'))
      .pipe(mergeMap(response => response.json()))
      .subscribe(data => {
        callback(data[0]);
      });
  }
}

// import { DataTableModule } from 'angular-4-data-table';
const routes: Routes = [
  {
    path: '',
    component: SectionComponent
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'fare-estimation',
    component: FareEstimationPageComponent
  },
  {
    path: 'servicecities',
    component: ServicecitiesComponent
  },
  {
    path: 'dashboard-section',
    component: DashboardSectionComponent
  },
  {
    path: 'driversignin',
    component: SigninComponent
  },
  {
    path: 'rider-signin',
    component: RiderSigninComponent
  },
  // {
  //   path: "driverProfile",
  //   component: DriverProfileComponent
  // },

  {
    path: 'help',
    component: HelpComponent
  },

  {
    path: 'section',
    component: SectionComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: 'driversignup',
    component: DriversignupComponent
  },

  {
    path: 'ridersignup',
    component: RidersignupComponent
  },

  // end.........................
  {
    path: 'faq',
    component: FaqComponent
  },
  {
    path: 'sign-in',
    component: HomesigninComponent
  },
  {
    path: 'privacy-policy',
    component: PrivacypolicyComponent
  },
  {
    path: 'terms-condition',
    component: TermsconditionComponent
  },
  {
    path: 'how-it-works',
    component: HowitworksComponent
  },
  {
    path: 'rider-terms',
    component: RiderTermsComponent
  },
  {
    path: 'driver-terms',
    component: DriverTermsComponent
  },
  {
    path: 'trust-safty-insurance',
    component: InsuranceComponent
  },
  {
    path: 'legal',
    component: LegalComponent
  },
  {
    path: 'profile',
    component: ProfileComponent
  },
  {
    path: 'earnings',
    component: EarningsComponent
  },
  {
    path: 'taxi',
    component: TaxiComponent
  },
  {
    path: 'trips',
    component: TripsComponent
  },
  {
    path: 'ridertrips',
    component: RiderTripsComponent
  },
  {
    path: 'riderwallet',
    component: RiderWalletComponent
  },
  {
    path: 'riderprofile',
    component: RiderProfileComponent
  },
  {
    path: 'riderforgotpassword',
    component: RiderForgotPassWordComponent
  },
  {
    path: 'driverforgotpassword',
    component: DriverForgotPassWordComponent
  },
  {
    path: '**',
    component: SectionComponent
  }
];
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SectionComponent,
    AboutComponent,
    ContactComponent,
    SigninComponent,
    HelpComponent,
    DriversignupComponent,
    ServicecitiesComponent,
    // DriverProfileComponent,
    HomesigninComponent,
    FaqComponent,
    PrivacypolicyComponent,
    TermsconditionComponent,
    HowitworksComponent,
    DriverTermsComponent,
    RiderTermsComponent,
    InsuranceComponent,
    LegalComponent,
    ProfileComponent,
    EarningsComponent,

    RidersignupComponent,
    RiderSigninComponent,
    TaxiComponent,
    TripsComponent,
    RiderTripsComponent,
    RiderProfileComponent,
    RiderWalletComponent,
    RiderForgotPassWordComponent,
    DriverForgotPassWordComponent,
    DashboardSectionComponent,
    FareEstimationPageComponent
  ],
  imports: [
    NgxMaskModule.forRoot(),
    AngularFireDatabaseModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(firebase),
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    BrowserModule,
    BrowserAnimationsModule,
    OwlModule,
    HttpClientModule,
    HttpModule,
    NgxSpinnerModule,
    NgxInputStarRatingModule,
    CarouselModule,
    OwlModule,
    FormsModule,
    Ng2SmartTableModule,
    RouterModule.forRoot(routes, { useHash: true }), // IndexModule
    ToastrModule.forRoot(),
    // MatCardModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatIconModule,
    MatToolbarModule,
    MatButtonModule,
    MatFormFieldModule,
    MatCardModule,
    MatInputModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    MatCheckboxModule,
    MatPaginatorModule,
    NguiMapModule.forRoot({
      apiUrl:
        'https://maps.google.com/maps/api/js?libraries=visualization,places,drawing'
    })
    // RecaptchaModule,
    // RecaptchaModule.forRoot(),
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    ButtonToasterService,
    AsyncPipe
  ],
  entryComponents: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

// tslint:disable-next-line: deprecation
const injector = ReflectiveInjector.resolveAndCreate([SeoSettingsService]);
const sr = injector.get(SeoSettingsService);

sr.getSEO(data => {
  if (data) {
    document
      .getElementById('description')
      .setAttribute('content', data.description);
    document.getElementById('keywords').setAttribute('content', data.keyword);
    document.getElementById('title').innerHTML = data.tag;
  }
});
