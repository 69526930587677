import { Component, OnInit, ViewChild, ElementRef, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { AppSettings } from 'src/app/app.config';
import { SectionService } from '../section/section.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { DataSharingService } from '../common/data-sharing.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { SigninService } from '../signin/signin.service';
import { NgForm } from '@angular/forms';
import * as jwtDecode from 'jwt-decode';
import { LocationService } from 'src/app/location.service';
declare var google;
declare var $: any;

@Component({
  selector: 'app-fare-estimation-page',
  templateUrl: './fare-estimation-page.component.html',
  providers: [SectionService, SigninService],
  styleUrls: ['./fare-estimation-page.component.css']
})
export class FareEstimationPageComponent implements OnInit, OnDestroy {

  @ViewChild('searchpickup')
  public searchPickupElementRef: any;

  @ViewChild('searchdrop')
  public searchDropElementRef: any;

  defaultZoom = AppSettings.ZOOM_MAP_LOCATION;
  defaultLocation = AppSettings.DEFAULT_LOCATION;
  geocoder = new google.maps.Geocoder();
  @ViewChild('mapElement') mapNativeElement: ElementRef;
  directionsService = new google.maps.DirectionsService;
  directionsDisplay = new google.maps.DirectionsRenderer;
  originAutocomplete = null;
  destinationAutocomplete = null;
  markers = [];
  map;
  originPlaceId = null;
  destinationPlaceId = null;
  showMapError: any = '';
  markerIcon = {
    start: {
      url: 'assets/images/pin_1.png',
      scaledSize: new google.maps.Size(26, 32),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(16, 16)
    },
    end: {
      url: 'assets/images/pin_2.png',
      scaledSize: new google.maps.Size(26, 32),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(16, 16)
    }
  };

  defaultUnit = AppSettings.defaultUnit;
  list1: any = {};
  vehicleAry: any[] = [];
  showVehicle = false;
  showBasic = false;
  NewListArr: any = {};
  NewList: any = {};
  distanceList: any = {};
  showError: any = '';
  ToggleFare = false;
  icon: any;
  temp: string = AppSettings.BASEURL;
  defaultCurr = AppSettings.defaultcur;
  tripType = 'daily';
  homecontent: any = {};
  responseText = ''; bookedT = false; reqTripID = ''; tripNotBooked = false; timeoutClear;

  userLoginDetails: any = {};
  @ViewChild('loginForm') loginForm: NgForm;
  decoded: any = {};
  getpickupFromFare: string;

  constructor(private sectionService: SectionService,
    private ngxToastr: ToastrService,
    private router: Router,
    private logService: SigninService,
    private locationservice: LocationService,
    private cdRef: ChangeDetectorRef, 
    private dataSharingService: DataSharingService) {
    this.getHomeContent();
    this.list1 = {};
    this.getpickupFromFare = localStorage.getItem('location');
    dataSharingService.isUserLoggedIn.subscribe(value => {
      // console.log(value);
      this.hideButton();
    });
    this.hideButton();
  }

  hideBu = false;

  hideButton() {
    const tok = localStorage.getItem('Tok');
    if (tok) {
      this.hideBu = true;
    } else {
      this.hideBu = false;
    }
  }

  getHomeContent() {
    this.sectionService.gethomecontent()
      .then(res => {
        this.homecontent = res[0];
      });
  }

  ngOnInit() {
    this.locationservice.locationData$
    .subscribe(sharedData => {
      this.mapTrigger(sharedData);
    })
  }

  findMe() {
    if (AppSettings.USE_NETWORK_GEOLOCATION) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
            AppSettings.LATITUDE = position.coords.latitude;
            AppSettings.LONGITUDE = position.coords.longitude;
            this.map = new google.maps.Map(this.mapNativeElement.nativeElement, {
              zoom: this.defaultZoom,
              center: { lat: AppSettings.LATITUDE, lng: AppSettings.LONGITUDE },
              mapTypeId: google.maps.MapTypeId.ROADMAP
            });
            this.directionsDisplay.setMap(this.map);  
/*
//To find country, Google map API key is needed.
            var country = false;
$.ajax({
  url: "https://maps.googleapis.com/maps/api/geocode/json",
  data: {
    latlng: AppSettings.LATITUDE+','+AppSettings.LONGITUDE,
    key: ""
  },
  context:this,
  success: function(result) {
    result.results.find(function(item,index) {
      item['address_components'].find(function(res) {
      if(res.types.includes('country') && res.short_name.toString().length==2)
      {
      country = res.short_name.toLowerCase();
      return true;
      } else 
      return false;
      
      });
      if(country!==false)
      return true;
      });
      if(country!==false && country.toString().length==2)
      {
      console.log(country);
      this.originAutocomplete.setComponentRestrictions({
        country: [country],
      });
      this.destinationAutocomplete.setComponentRestrictions({
        country: [country],
      });
     }

  }
});
*/
        });
      } else {
        // console.log("Geolocation is not supported by this browser.");
      }
    }
  }

  initializeMap() {
    this.map = new google.maps.Map(this.mapNativeElement.nativeElement, {
      zoom: this.defaultZoom,
      center: { lat: AppSettings.LATITUDE, lng: AppSettings.LONGITUDE },
      mapTypeId: google.maps.MapTypeId.ROADMAP
    });
    this.directionsService = new google.maps.DirectionsService();
    this.directionsDisplay =
      new google.maps.DirectionsRenderer({
        suppressMarkers: true, polylineOptions: {
          strokeColor: '#006400', strokeOpacity: 0.7,
          strokeWeight: 5
        }
      });
    this.directionsDisplay.setMap(this.map);

    /** Daily Rides */

    // this.originPlaceId = null;
    // this.destinationPlaceId = null;

    // const originInput = document.getElementById('origin-input');
    // const destinationInput = document.getElementById('destination-input')

    // this.originAutocomplete = new google.maps.places.Autocomplete(originInput);
    // this.originAutocomplete.setFields(['place_id']);
    
    // this.destinationAutocomplete =
    //   new google.maps.places.Autocomplete(destinationInput);
    // this.destinationAutocomplete.setFields(['place_id']);

    // this.setupPlaceChangedListener(this.originAutocomplete, 'ORIG');
    // this.setupPlaceChangedListener(this.destinationAutocomplete, 'DEST');
    this.originPlaceId = null;
    this.destinationPlaceId = null;
    const originAutocomplete = new google.maps.places.Autocomplete(document.getElementById('origin-input'));
    originAutocomplete.setFields(['place_id']);
    const destinationAutocomplete =
      new google.maps.places.Autocomplete(document.getElementById('destination-input'));
    destinationAutocomplete.setFields(['place_id']);
    this.setupPlaceChangedListener(originAutocomplete, 'ORIG');
    this.setupPlaceChangedListener(destinationAutocomplete, 'DEST');
  }

  // tslint:disable-next-line: use-life-cycle-interface
  ngAfterViewInit(): void {
    // this.initializeMap();
    this.findMe();
    if (localStorage.getItem('location') === null) {
      this.initializeMap();
    }
    else {
      this.mapTrigger(this.getpickupFromFare)
    }
    this.cdRef.detectChanges(); 
  }

  setupPlaceChangedListener(autocomplete, mode) {
    const me = this;
    autocomplete.addListener('place_changed', function () {
      me.markers.forEach(function (marker) {
        marker.setMap(null);
      });
      me.markers = [];
      const place = autocomplete.getPlace();
      if (!place.place_id) {
        this.showMapError = 'Please Enter Correct Location.';
        return;
      }
      if (mode === 'ORIG') {
        me.originPlaceId = place.place_id;
        me.setIndividualMarker(place.place_id);
      } else {
        me.destinationPlaceId = place.place_id;
        me.setIndividualMarker(place.place_id);
      }
      me.route();
    });
  }

  setIndividualMarker(request) {
    const me = this;
    me.geocoder.geocode({ 'placeId': request }, function (results, status) {
      if (status === 'OK') {
        if (results[0]) {
          const leg = results[0];
          me.showMapError = '';
          me.map.setZoom(15);
          me.makeMarker(leg.geometry.location, me.markerIcon.start, leg.formatted_address);
          me.map.setCenter(results[0].geometry.location);
        } else {
          me.showMapError = 'No Data Found';
          me.clearMap();
        }
      } else {
        me.showMapError = 'Geocoder failed due to: ' + status;
      }
    });
  }

  route() {
    if (!this.originPlaceId || !this.destinationPlaceId) {
      return;
    }
    const me = this;
    me.directionsService.route({
      origin: { 'placeId': me.originPlaceId },
      destination: { 'placeId': me.destinationPlaceId },
      travelMode: 'DRIVING'
    }, (response, status) => {
      if (status === 'OK') {
        me.showVehicle = false;
        me.directionsDisplay.setDirections(response);
        const leg = response.routes[0].legs[0];
        me.list1 = {
          pick: leg.start_address,
          pickupLat: leg.start_location.lat(),
          pickupLng: leg.start_location.lng(),
          dropLat: leg.end_location.lat(),
          dropLng: leg.end_location.lng(),
          drop: leg.end_address
        };
        me.showMapError = '';
        // me.getVehicles();
        me.markers.forEach(function (marker) {
          marker.setMap(null);
        });
        me.markers = [];
        me.makeMarker(leg.start_location, me.markerIcon.start, leg.start_address);
        me.makeMarker(leg.end_location, me.markerIcon.end, leg.end_address);
      } else {
        me.showMapError = 'Directions request failed due to ' + status;
        me.clearMap();
      }
    });
  }

  makeMarker(position, icon, title) {
    this.markers.push(new google.maps.Marker({
      position: position,
      animation: google.maps.Animation.DROP,
      icon: icon,
      map: this.map,
      draggable: false,
      title: title
    }));
  }

  clearMap() {
    this.directionsDisplay.set('directions', null);
    this.map = new google.maps.Map(this.mapNativeElement.nativeElement, {
      zoom: this.defaultZoom,
      center: { lat: AppSettings.LATITUDE, lng: AppSettings.LONGITUDE },
      mapTypeId: google.maps.MapTypeId.ROADMAP
    });
    this.directionsDisplay.setMap(this.map);
  }

  getFareEstimation(event) {
    // console.log(event.target.name);
    // console.log(event.target.value);
  }

  getVehicles() {
    if (this.list1.pickupLat
      && this.list1.pickupLng
      && this.list1.dropLng
      && this.list1.dropLat) {
      this.sectionService.getVehicles(this.list1)
        .then(res => {
          this.list1.serviceTypeId = undefined;
          this.vehicleAry = res.vehicleCategories;
          this.showVehicle = false;
          this.showBasic = false;
          this.showMapError = '';
          this.router.navigate(['/dashboard-section'])
        }).catch(res => {
          this.showVehicle = false;
          this.showBasic = false;
          const errorMessage = res;
          this.showMapError = errorMessage.message;
          this.router.navigate(['/rider-signin'])
          return false
        });
    }
  }

  SetVehicleType(data: any, inputs: any): void {
    this.bookedT = false;
    this.reqTripID = '';
    this.tripNotBooked = false;
    if (!data) { return; }
    this.showBasic = true;
    const selectElementText = event.target['options']
    [event.target['options'].selectedIndex].text;
    for (const item of this.vehicleAry) {
      if (item.type === selectElementText) {
        this.icon = this.temp + item.file;
      }
    }
    this.list1.vehicletype = selectElementText;
    const selectElementId = event.target['options']
    [event.target['options'].selectedIndex].value;
    if (selectElementId > 0 || selectElementId !== undefined) {
      this.list1.share = true;
      this.list1.noofshare = selectElementId;
    }
    if (selectElementId === 'undefined') {
      this.list1.share = false;
      this.list1.noofshare = 0;
    }
    inputs.bookingType = 'rideNow';
    this.sectionService.conBook(inputs)
      .then(res => {
        this.ToggleFare = false;
        this.list1['estimationId'] = res.estimationId;
        this.NewListArr = res.vehicleDetailsAndFare;
        this.NewList = this.NewListArr.fareDetails;
        this.distanceList = res.distanceDetails;
        this.NewList.paymentMode = 'Cash';
      })
      .catch(res => {
        this.ToggleFare = true;
        const errorMessage = res;
        this.showError = errorMessage.message;
      });
  }

  clearPage() {
    this.responseText = '';
    this.bookedT = false;
    this.reqTripID = '';
    this.tripNotBooked = false;
    this.markers = [];
    this.list1 = {};
    this.originPlaceId = null;
    this.destinationPlaceId = null;
    this.showMapError = '';
    this.vehicleAry = [];
    this.showVehicle = false;
    this.showBasic = false;
    this.NewListArr = {};
    this.NewList = {};
    this.distanceList = {};
    this.showError = '';
    this.ToggleFare = false;
    this.temp = AppSettings.BASEURL;
    this.defaultZoom = AppSettings.ZOOM_MAP_LOCATION;
    this.defaultLocation = AppSettings.DEFAULT_LOCATION;
    this.directionsDisplay.set('directions', null);
    localStorage.removeItem('location')
    localStorage.removeItem('droplocation')
    this.initializeMap();
  }

  bookDaily(inputs) {
    const bookTripObj = {
      tripType: 'daily',
      paymentMode: 'Cash',
      requestFrom: 'web',
      bookingType: 'rideNow',
      serviceType: inputs.vehicletype,
      estimationId: inputs.estimationId,
      tripTime: '',
      notesToDriver: '',
      bookingFor: '',
      otherPh: '',
      otherPhCode: '',
      noofseats: 1,
      pickupCity: '',
      promo: '',
      promoAmt: '',
      tripDate: '',
      riderId: localStorage.getItem('Id')
    };
    this.sectionService.requestTaxi(bookTripObj)
      .then(response => {
        this.responseText = response['message'];
        this.reqTripID = response['tripId'];
        this.bookedT = true;
        this.tripNotBooked = false;
      })
      .catch(response => {
        this.responseText = response['message'];
        this.bookedT = true;
        this.reqTripID = '';
        this.tripNotBooked = true;
      });
  }

  routeToMyTrips() {
    this.router.navigate(['processing-trips'], {
      queryParams: {
        tripId: this.reqTripID
      },
      queryParamsHandling: 'merge',
    });
  }

  /** Login popup */

  handleLoginEvent(e) { }

  loginRider(loginForm): void {
    $('#loadMe').modal({
      backdrop: 'static',
      keyboard: false,
      show: true
    });
    const addRider = {
      username: loginForm.email,
      password: loginForm.password
    };
    this.logService.riderSignme(addRider)
      .then(msg => {
        localStorage.setItem('Tok', msg.token);
        this.decoded = jwtDecode(msg.token);
        localStorage.setItem('Id', this.decoded.id);
        localStorage.setItem('Type', this.decoded.type);
        this.dataSharingService.isUserLoggedIn.next(true);
        $('#loadMe').modal('hide');
        $('#myModal').modal('toggle');
        this.loginForm.resetForm();
        this.ngxToastr.success(msg['message']);
      })
      .catch(res => {
        this.ngxToastr.error(res['message']);
        this.timeoutClear = setTimeout(function () {
          $('#loadMe').modal('hide');
        }, 2000);
      });
  }

  ngOnDestroy() {
    clearTimeout(this.timeoutClear);
  }
  mapTrigger(sharedData) {
    this.map = new google.maps.Map(this.mapNativeElement.nativeElement, {
      zoom: this.defaultZoom,
      center: { lat: AppSettings.LATITUDE, lng: AppSettings.LONGITUDE },
      mapTypeId: google.maps.MapTypeId.ROADMAP
    });
    this.directionsService = new google.maps.DirectionsService();
    this.directionsDisplay =
      new google.maps.DirectionsRenderer({
        suppressMarkers: true, polylineOptions: {
          strokeColor: '#006400', strokeOpacity: 0.7,
          strokeWeight: 5
        }
      });
    this.directionsDisplay.setMap(this.map);

    /** Daily Rides */

    // this.originPlaceId = null;
    // this.destinationPlaceId = null;
    // const originInput = document.getElementById('origin-input');
    // const destinationInput = document.getElementById('destination-input');
    // const originAutocomplete = new google.maps.places.Autocomplete(originInput);
    // originAutocomplete.setFields(['place_id']);
    // const destinationAutocomplete =
    //   new google.maps.places.Autocomplete(destinationInput);
    // destinationAutocomplete.setFields(['place_id']);
    // this.setupPlaceChangedListener(originAutocomplete, 'ORIG');
    // this.setupPlaceChangedListener(destinationAutocomplete, 'DEST');
    this.originPlaceId = null;
    this.destinationPlaceId = null;
    const originAutocomplete = new google.maps.places.Autocomplete(document.getElementById('origin-input'));
    originAutocomplete.setFields(['place_id']);
    const destinationAutocomplete =
      new google.maps.places.Autocomplete(document.getElementById('destination-input'));
    destinationAutocomplete.setFields(['place_id']);
    this.setupPlaceChangedListener(originAutocomplete, 'ORIG');
    this.setupPlaceChangedListener(destinationAutocomplete, 'DEST');

    let getData = JSON.parse(sharedData)
    let DropFare = localStorage.getItem('droplocation')
    if(DropFare !== null){
      let getDropData = JSON.parse(DropFare) 
      this.list1.drop = getDropData["drop"]
      this.destinationPlaceId= getDropData["placeId"];
    }
    this.list1.pick = getData["pick"];
    this.originPlaceId = getData["placeId"];
    console.log(this.originPlaceId)
    this.setIndividualMarker[this.originPlaceId];
    const me = this;
    if(localStorage.getItem('droplocation') === null){
      this.geocoder.geocode({ 'placeId': this.originPlaceId }, function (results, status) {
        if (status === 'OK') {
          if (results[0]) {
            const leg = results[0];
            me.showMapError = '';
            me.map.setZoom(15);
            me.makeMarker(leg.geometry.location, me.markerIcon.start, leg.formatted_address);
            // me.makeMarker(leg.end_location, me.markerIcon.end, leg.end_address);
            me.map.setCenter(results[0].geometry.location);
          }
          
          else {
            this.showMapError = 'No Data Found';
            this.clearMap();
          }
        } else {
          this.showMapError = 'Geocoder failed due to: ' + status;
        }
      });
    }else{
      console.log("--------------rote")
      me.route();
    }
  }
  from = {
    lat: 0,
    lng: 0,
  };
  to = {
    lat: 0,
    lng: 0,
  };
  placeChangedP(place) {
    console.log(place,"===============>")
    this.list1.pick = place.formatted_address;
    this.list1.pickupLat = this.from.lat;
    this.list1.pickupLng = this.from.lat;
    this.list1.placeId = place.place_id;
    // this.list1.Type = this.tripType
    localStorage.setItem('location', JSON.stringify(this.list1))
    this.locationservice.setLocationInfo(JSON.stringify(this.list1))
  }
  dropplaceChangedP(place) {
    this.list1.drop = place.formatted_address;
    this.list1.placeId = place.place_id;
    localStorage.setItem('droplocation', JSON.stringify(this.list1))
  }
}
