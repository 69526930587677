import { Component } from "@angular/core";
import { CommonService } from "../common/common.service";

@Component({
  selector: "faq",
  templateUrl: "./faq.html",
  styleUrls: ["./faq.component.css"],
  providers:[CommonService]
})
export class FaqComponent 
{
  rotationDegrees: number[] = [];
  catary:any[]=[];
  AnsAry:any[]=[];
  title:string;
  initial: number;
  emptypanel: boolean;
  constructor(private cservice:CommonService){
    console.log(this.rotationDegrees)
    this.initial = 0
    this.title="FAQ";
    this.cservice.getfaqCatagory()
  .then( msg =>{
    this.emptypanel = msg.length == 0
      this.catary = msg;
      // console.log(msg)
    }); 
  //  console.log(this.catary)
  }
  getQuestions(id){
    // console.log(id)
    this.initial = 1
    this.cservice.getQuesAnswer(id)
    .then(res=>{
      this.emptypanel = res.length == 0
      this.AnsAry=res;
      this.rotationDegrees = Array(this.AnsAry.length).fill(0);
    })
    
    // console.log(this.AnsAry)
  }


  // Function to toggle rotation value
  toggleRotation(index) {
    if (!this.rotationDegrees) {
      this.rotationDegrees = Array(this.AnsAry.length).fill(0);
  }
    console.log(index,this.rotationDegrees)
    // Add your logic to increase rotation value here
    // this.rotationDegrees += 180; // Increase by 180 degrees
    console.log(this.rotationDegrees[index])
    this.rotationDegrees[index] = this.rotationDegrees[index] === 0 ? 180 : 0;
  }
  goback(){
    this.initial = 0
    this.AnsAry = []
    this.emptypanel = false
  }
}
