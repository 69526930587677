import { Component } from "@angular/core";
import { CommonService } from "../common/common.service";

@Component({
  selector: "help",
  templateUrl: "./help.html",
  styleUrls: ['./help.component.css'],
  providers: [CommonService]
})
export class HelpComponent {
  rotationDegrees: number[] = [];
  catary: any[] = [];
  AnsAry: any[] = [];
  title: string;
  initial: number;
  emptypanel: boolean;
  constructor(private cservice: CommonService) {
    this.initial = 0
    this.title = "How It Works";
    this.cservice.gethelpCatagory()
      .then(msg =>{
        this.emptypanel = msg.length == 0
        this.catary = msg
      }) 
  }

  getQuestions(id) {
    //console.log(id)
    this.initial = 1
    this.cservice.getHelpQA(id)
      .then(res => {
        this.emptypanel = res.length == 0
        this.AnsAry = res;
      })
    // console.log(this.AnsAry)
  }
  toggleRotation(index) {
    if (!this.rotationDegrees) {
      this.rotationDegrees = Array(this.AnsAry.length).fill(0);
  }
    console.log(index,this.rotationDegrees)
    // Add your logic to increase rotation value here
    // this.rotationDegrees += 180; // Increase by 180 degrees
    console.log(this.rotationDegrees[index])
    this.rotationDegrees[index] = this.rotationDegrees[index] === 0 ? 180 : 0;
  }
  goback(){
    this.initial = 0
    this.AnsAry = []
    this.emptypanel = false
  }
}
