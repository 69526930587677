import { Component, ViewChild, OnInit } from '@angular/core';
import { DriverService } from './driver.service';
import { CommonService } from '../common/common.service';
import { ButtonToasterService } from '../buttontoaster/buttontoaster.service';
import { VerificationSettings, AppSettings } from 'src/app/app.config';
import { Router } from '@angular/router';
@Component({
  selector: "driversignup",
  templateUrl: './driversignup.html',
  providers: [ButtonToasterService, DriverService]
})

export class DriversignupComponent implements OnInit {
  companyary: any[] = [];
  countryary: any[] = [];
  stateary: any[] = [];
  cityary: any[] = [];
  langary: any[] = [];
  currencyary: any[] = [];
  list: any = {};
  apiMessage: string;
  Tmsg: any = '';
  Tcount = true;
  Tcode: any = {};
  accept_terms = false;
  initial = '';

  showVerify = VerificationSettings.isVerify;
  verifyMeth = VerificationSettings.verifyMethod;

  defaultOTP;
  defaultPhone;

  verifyinputs = {};
  otpInputs = {};

  constructor(private dataService: DriverService,
    private toastr: ButtonToasterService,
    private router: Router) {
    this.verifyinputs = {};
    this.verifyinputs['phcode'] = AppSettings.defaultPhoneCode;
    this.otpInputs = {};
    this.defaultOTP = '';
    if (this.showVerify) {
      this.initial = 'showVerify';
    } else {
      this.initial = 'addDriverScreen';
    }
  }

  verifyUser(data) {
    this.list = {};
    if (data.phone) {
      this.list.phone = data.phone;
      data.phcode = '+' + data.phcode;
    } else if (data.email) {
      this.list.email = data.email;
    }
    this.dataService.verifyDriver(data)
      .then(res => {
        this.defaultOTP = (res.code).toString();
        this.toastr.showtoast('success', res.message);
        this.initial = 'showOTP';
      })
      .catch(res => {
        this.toastr.showtoast('error', res.message);
      });
  }

  verifyOTP(data) {
    if (data === this.defaultOTP) {
      this.toastr.showtoast('success', 'OTP Verified Successfully');
      this.initial = 'addDriverScreen';
    } else {
      this.toastr.showtoast('error', 'Invalid OTP');
    }
  }

  @ViewChild('dataForm') form: any;

  clearMsg(): void {
    this.apiMessage = '';
  }

  AddNewDoc(inputs: any): void {
    if (!inputs) { return; }
    if(inputs.accept_terms!==true) {
      return;
    }
    this.dataService.createDoc(inputs)
      .then(msg => {
        this.toastr.showtoast('success', msg.message);
        this.form.reset();
        this.router.navigate(['/driversignin']);
      })
      .catch(msg => {
        console.log(msg);
        const err = JSON.parse(msg._body);
        console.log(err.message);
        this.toastr.showtoast('error', err.message);
      });
  }

  ngOnInit(): void {
    this.dataService.getCompanies()
      .then(msg => this.companyary = msg);
    this.dataService.getCountries()
      .then(msg => this.countryary = msg[0]['countries']);
    this.dataService.getLangs()
      .then(msg => this.langary = msg[0]['datas']);
    this.dataService.getCurrency()
      .then(msg => this.currencyary = msg[0]['datas']);
  }

  GetState(data: any): void {
    if (!data) { return; }
    const selectElementText = event.target['options']
    [event.target['options'].selectedIndex].text;
    this.list.cntyname = selectElementText;

    const selectElementId = event.target['options']
    [event.target['options'].selectedIndex].value;

    this.dataService.GetState(selectElementId)
      .then(msg => {
        this.stateary = msg[0]['states'];
      });
  }

  testCode(inp) {
    this.Tcode.code = inp;
    this.Tcount = true;
    console.log(inp);
    this.dataService.AvailCode(this.Tcode).then(
      res => {
        //  console.log(res);
        if (res.success == false) {
          this.Tcount = false;
          this.Tmsg = res.message;
          this.list.code = "";
          this.Tcount = true;
          this.toastr.showtoast('error', res.message);
          //  console.log("false ZM");

          //   console.log(this.Tmsg);
        } else if (res.success == true) {
          this.Tcount = true;
          // console.log("true ZM");

          //   console.log(res.message);
        }
      }
    );
  }

  GetCity(data: any): void {
    if (!data) { return; }
    const selectElementText = event.target['options']
    [event.target['options'].selectedIndex].text;
    this.list.statename = selectElementText;

    const selectElementId = event.target['options']
    [event.target['options'].selectedIndex].value;

    this.dataService.GetCity(selectElementId)
      .then(msg => {
        this.cityary = msg[0]['cities'];
      })
  }

  SetCity(data: any): void {
    if (!data) { return; }
    const selectElementText = event.target['options']
    [event.target['options'].selectedIndex].text;
    this.list.cityname = selectElementText;
  }

}
