/**
 * @license
 * Copyright Abservetech. All Rights Reserved.
 */

export class AppSettings {
  // public static API = "http://34.70.81.245:3001/";
  // public static API = "http://34.67.121.212:3001/";
  public static API = "https://api.timigo.app/";
  // public static API = "http://135.125.75.42:3001/";
  public static API_ENDPOINT = AppSettings.API + "adminapi/";
  public static VEH_ENDPOINT = AppSettings.API + "api/";
  public static WEB_ENDPOINT = AppSettings.API + "webapi/";
  public static DRI_ENDPOINT = AppSettings.API + "api/";
  public static FILEURL = AppSettings.API + "public/";
  public static BASEURL = AppSettings.API;
  public static FEURL = AppSettings.API + "landing/";
  public static APPNAME = "IB Taxi Timigo";
  public static COPYRIGHTURL_FOOTER = "IB Taxi Timigo";
  public static LOGO_WIDTH = "200";
  public static LOGO_HEIGHT = "70";
  public static LOGO_URL = "assets/images/logo.png";
  public static DEFAULT_LOCATION = "Italy";
  public static LATITUDE = 41.8719;
  public static LONGITUDE = 12.5674;
  public static ZOOM_MAP_LOCATION = 7;
  public static USE_NETWORK_GEOLOCATION = true;
  public static toShowSeocndCurrency = false;
  public static secondCurrency = "₨";
  public static defaultcur = "₨";
  public static defaultTime = "Mins";
  public static defaultUnit = "KM";
  public static defaultPhoneCode = "+91";
  public static showServiceCity = false;
  public static showWhatWeOffers = false;
}

export class VerificationSettings {
  public static isVerify = true;
  public static verifyMethod = "phone"; // ['phone','email']
  public static methodName = "Phone Number"; // ['Email Address','Phone Number']
}
export class LanguageSettings {
  public static languages = ["en", "es"];
  public static defaultSelectedLang = "en";
  public static setLanguageForMenus = true;
}

export class DriverDocuments {
  public static Documents = [
    { label: "Insurance", value: "insurance" },
    { label: "Taxi Passing", value: "passing" },
    { label: "Driving Licence", value: "licence" },
  ];
}

// abservetech
