// ./angular-client/src/app/todo/todo.service.ts
import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { AppSettings } from '../../app.config';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';

@Injectable()
export class SectionService {
  private apiUrl = AppSettings.WEB_ENDPOINT;
  private apiTUrl = AppSettings.API_ENDPOINT;

  initial: boolean;
  TapiUrl: string = AppSettings.VEH_ENDPOINT;
  token: string = localStorage.getItem('Tok');

  constructor(private http: Http) {
    // this.initial=false;
  }

  getDriverDetailsForATrip(inputs: any) {
    let headers = new Headers();
    headers.append('x-access-token', this.token);
    let opts = new RequestOptions();
    opts.headers = headers;
    return this.http.put(this.TapiUrl + 'tripDriverDetails/', inputs, opts)
      .toPromise()
      .then(this.handleData)
      .catch(this.handleError);
  }

  riderFeedback(inputs: any) {
    let headers = new Headers();
    headers.append('x-access-token', this.token);
    let opts = new RequestOptions();
    opts.headers = headers;
    return this.http.put(this.TapiUrl + 'riderFeedback/', inputs, opts)
      .toPromise()
      .then(this.handleData)
      .catch(this.handleError);
  }

  getVehicles(inputs): Promise<any> {
    // console.log(tok)
    let headers = new Headers();
    headers.append('x-access-token', this.token);
    let opts = new RequestOptions();
    opts.headers = headers;
    return this.http.post(this.TapiUrl + 'serviceBasicFare/daily', inputs,opts)
      .toPromise()
      .then(this.handleData)
      .catch(this.handleError);
  }

  requestTaxi(inputs: any): Promise<any> {
    let headers = new Headers();
    headers.append('x-access-token', this.token);
    let opts = new RequestOptions();
    opts.headers = headers;
    return this.http.post(this.TapiUrl + 'requestTaxi/', inputs,opts)
      .toPromise()
      .then(this.handleData)
      .catch(this.handleError);
  }

  listVehicles(): Promise<any> {
    return this.http.get(this.apiTUrl + 'serviceAvailable/')
      .toPromise()
      .then(this.handleData)
      .catch(this.handleError);
  }

  conBook(inputs: any): Promise<any> {
    let headers = new Headers();
    headers.append('x-access-token', this.token);
    let opts = new RequestOptions();
    opts.headers = headers;
    return this.http.post(this.TapiUrl + 'estimationFare/', inputs,opts)
      .toPromise()
      .then(this.handleData)
      .catch(this.handleError);
  }

  getOurDrivers(): Promise<any> {
    return this.http.get(this.apiTUrl + 'ourDrivers/')
      .toPromise()
      .then(this.handleData)
      .catch(this.handleError);
  }

  gethomecontent(): Promise<any> {
    return this.http.get(this.apiTUrl + 'gethomecontent/')
      .toPromise()
      .then(this.handleData)
      .catch(this.handleError);
  }

  private handleData(res: any) {
    const body = res.json();
    return body || {};
  }
  // createDoc(inputs:any): Promise<any>{
  //   let headers = new Headers();
  //   headers.append('x-access-token', myVariable);
  //   let opts = new RequestOptions();
  //   opts.headers = headers;

  //   return this.http.post(this.apiUrl + 'admin/', inputs, opts)
  //   .toPromise()
  //   .then(this.handleData)
  //   .catch(this.handleError)
  // }

  private handleError(error: any): Promise<any> {
    const body = error.json();
    return Promise.reject(body || error);
  }

  public getApiUrl() {
    return this.apiUrl + ' ';
  }

}
