import { Component, OnInit, ViewChild, ChangeDetectorRef, OnDestroy, ElementRef } from '@angular/core';
import { AppSettings } from 'src/app/app.config';
import { DirectionsRenderer } from '@ngui/map';
import { SectionService } from '../section/section.service';
import * as moment from 'moment';
import { ButtonToasterService } from '../buttontoaster/buttontoaster.service';
import { AngularFireDatabase } from '@angular/fire/database';
// import { GeoFire } from 'geofire';
import * as RX from 'rxjs';
import * as firebase from 'firebase';
import { of, from, Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { LocationService } from 'src/app/location.service';
declare var google;

@Component({
  selector: 'app-dashboard-section',
  providers: [SectionService, AngularFireDatabase],
  templateUrl: './dashboard-section.component.html',
  styleUrls: ['./dashboard-section.component.css']
})
export class DashboardSectionComponent implements OnInit, OnDestroy {

  initial = 'tripBookingScreen';
  list1: any = {};
  vehicleAry: any[] = [];
  showVehicle = false;
  showBasic = false;
  NewListArr: any = {};
  NewList: any = {};
  distanceList: any = {};
  showError: any = '';
  ToggleFare = false;
  icon: any;
  temp: string = AppSettings.BASEURL;

  bookingTypes: any = [];
  estimationId: string;
  message;
  dbRef: any;

  tripID;

  bookedDriverDetails;
  bookedTaxiDetails;
  bookedLocationDetails;
  overallBooked;
  riderTrip;
  tripfinalDet;
  feedBackDetails: any = {};

  defaultUnit = AppSettings.defaultUnit;
  deafultTime = AppSettings.defaultTime;
  defaultCurr = AppSettings.defaultcur;

  driverNotAvailable = false;
  showFareBook = false;
  hideBook = false;
  tripDataTime: any;
  spinnerTimeout: any;

  @ViewChild('searchpickup')
  public searchPickupElementRef: any;

  @ViewChild('searchdrop')
  public searchDropElementRef: any;

  defaultZoom = AppSettings.ZOOM_MAP_LOCATION;
  defaultLocation = AppSettings.DEFAULT_LOCATION;
  geocoder = new google.maps.Geocoder();
  @ViewChild('mapElement') mapNativeElement: ElementRef;
  directionsService = new google.maps.DirectionsService;
  directionsDisplay = new google.maps.DirectionsRenderer;
  markers = [];
  map;
  originPlaceId = null;
  destinationPlaceId = null;
  showMapError: any = '';
  markerIcon = {
    start: {
      url: 'assets/images/pin_1.png',
      scaledSize: new google.maps.Size(26, 32),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(16, 16)
    },
    end: {
      url: 'assets/images/pin_2.png',
      scaledSize: new google.maps.Size(26, 32),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(16, 16)
    }
  };
  reqTripID = '';
  getpickupFromFare: string;

  constructor(private cdr: ChangeDetectorRef,
    private toastr: ButtonToasterService,
    private router: Router,
    private db: AngularFireDatabase,
    private spinner: NgxSpinnerService,
    private cdRef: ChangeDetectorRef, 
    private locationservice: LocationService,
    private sectionService: SectionService) {
    // this.clearAllData();
    this.getpickupFromFare = localStorage.getItem('location');
  }

  // tslint:disable-next-line: use-life-cycle-interface
  ngAfterViewInit(): void {
    if (localStorage.getItem('location') === null) {
      this.initializeMap();
    }
    else {
      this.mapTrigger(this.getpickupFromFare)
    }
    this.cdRef.detectChanges(); 
  }

  ngOnInit() {
    this.spinner.show();
    this.spinnerTimeout = setTimeout(() => {
      this.spinner.hide();
    }, 2000);
    // this.readItems();
    // this.valueChanged$.subscribe(data => this.doStuff(data));
    this.locationservice.locationData$
    .subscribe(sharedData => {
      console
      this.mapTrigger(sharedData);
    })
  }

  getDrag(event) { }

  hideVehicle(e) {
    this.showVehicle = false;
    this.ToggleFare = false;
  }

  getFareEstimation() {
    if (this.list1.pickupLat
      && this.list1.pickupLng
      && this.list1.dropLat
      && this.list1.dropLng) {
      this.spinner.show();
      this.sectionService.getVehicles(this.list1)
        .then(res => {
          this.vehicleAry = res.vehicleCategories;
          this.showVehicle = true;
          this.bookingTypes = [
            {
              'disabled': false,
              'value': 'rideNow',
              'label': 'Ride Now'
            },
            {
              'disabled': true,
              'value': 'rideLater',
              'label': 'Ride Later (Scheduled Trip)'
            }
          ];
          this.list1.bookingType = 'rideNow';
          this.spinner.hide();
        })
        .catch(response => {
          this.spinner.hide();
          this.toastr.showtoast('error', response.message);
        });
    }
  }

  SetVehicleType(data: any, inputs: any): void {
    if (!data) { return; }
    this.spinner.show();
    this.showFareBook = true;
    this.showBasic = true;
    const selectElementText = event.target['options']
    [event.target['options'].selectedIndex].text;
    // console.log(selectElementText)
    for (const item of this.vehicleAry) {
      if (item.type === selectElementText) {
        this.icon = this.temp + item.file;
      }
    }
    this.list1.vehicletype = selectElementText;
    const selectElementId = event.target['options']
    [event.target['options'].selectedIndex].value;
    if (selectElementId > 0 || selectElementId !== undefined) {
      this.list1.share = true;
      this.list1.noofshare = selectElementId;
    }
    if (selectElementId === 'undefined') {
      this.list1.share = false;
      this.list1.noofshare = 0;
    }
    this.sectionService.conBook(inputs)
      .then(res => {
        this.ToggleFare = false;
        this.estimationId = res.estimationId;
        this.NewListArr = res.vehicleDetailsAndFare;
        this.NewList = this.NewListArr.fareDetails;
        this.distanceList = res.distanceDetails;
        this.NewList.paymentMode = 'Cash';
        this.spinner.hide();
      })
      .catch(res => {
        this.spinner.hide();
        this.ToggleFare = true;
        this.showError = res.message;
      });
  }

  bookTrip(inputs) {
    // this.spinner.show();
    const bookTripObj = {
      tripType: 'daily',
      paymentMode: 'Cash',
      requestFrom: 'app',
      bookingType: inputs.bookingType,
      serviceType: inputs.vehicletype,
      estimationId: this.estimationId,
      tripTime: '',
      notesToDriver: '',
      bookingFor: '',
      otherPh: '',
      otherPhCode: '',
      noofseats: 1,
      pickupCity: '',
      promo: '',
      promoAmt: '',
      tripDate: '',
      riderId: localStorage.getItem('Id')

    };
    if (inputs.bookingType === 'rideLater') {
      bookTripObj.tripDate = moment(this.list1.tripTime, 'YYYY-MM-DDTHH:mm:ss').format('DD-MM-YYYY');
      bookTripObj.tripTime = moment(this.list1.tripTime, 'YYYY-MM-DDTHH:mm:ss').format('hh:mm A');
    }
    this.sectionService.requestTaxi(bookTripObj)
      .then(response => {
        this.reqTripID = response['tripId'];
        this.hideBook = true;
        // this.tripID = response.requestDetails;
        this.toastr.showtoast('success', response['message']);
        this.routeToMyTrips()
        this.readItems();
        this.clearAllData()
      })
      .catch(response => {
        this.spinner.hide();
        this.toastr.showtoast('error', response.message);
      });
  }

  /* Listen For Request Changes */

  // tslint:disable-next-line: member-ordering
  valueChanged$ = new Subject();

  // tslint:disable-next-line: member-ordering
  tripvalueChanged$ = new Subject();

  readItems() {
    const riderId = localStorage.getItem('Id');
    return this.db.database.ref('riders_data/' + riderId)
      .on('value', snap => this.callback(snap.val()));
  }

  callback(val) {
    this.riderTrip = val;
    this.valueChanged$.next(val);
    this.tripvalueChanged$.subscribe(data => this.doStuff(data));
    // this.spinner.hide();
    if (this.riderTrip.current_tripid !== ''
      && this.riderTrip.current_tripid !== undefined
      && this.riderTrip.current_tripid !== 0
      && this.riderTrip.current_tripid !== '0') {
      this.tripID = this.riderTrip.current_tripid;
      this.getTripBookedDetails(this.riderTrip.current_tripid);
      this.getDriverDetails(this.riderTrip.current_tripid);
      console.log("iF function")
    } else {
      console.log("else function")
      this.showFareBook = false;
      this.driverNotAvailable = true;
      if (this.riderTrip.tripstatus) {
        this.toastr.showtoast('warn', this.riderTrip.tripstatus);
      }
    }
  }

  getDriverDetails(tripid) {
    const trip = { tripId: tripid };
    this.spinner.show();
    this.sectionService.getDriverDetailsForATrip(trip)
      .then(res => {
        this.overallBooked = res;
        this.bookedDriverDetails = res['driver'][0].profile;
        this.bookedTaxiDetails = res['driver'][1].currentActiveTaxi;
        this.bookedLocationDetails = res.pickupdetails;
        this.bookedTaxiDetails.taxiDet = this.bookedTaxiDetails.vehicletype
          + ' -- ' + this.bookedTaxiDetails.makename + ' -- '
          + this.bookedTaxiDetails.model + ' | ' + this.bookedTaxiDetails.licence;
        this.bookedDriverDetails.drivDet = this.bookedDriverDetails.fname + ' -- ' + this.bookedDriverDetails.phone;
        // this.initial = 'trackingScreen';
        this.spinner.hide();
      })
      .catch(response => {
        this.spinner.hide();
        const error = JSON.parse(response._body);
        this.toastr.showtoast('error', error.message);
      });
  }

  getTripBookedDetails(tripid) {
    this.tripDataTime = setInterval(() => {
      return this.db.database.ref('trips_data/' + tripid)
        .on('value', snap => this.finalTripDetails(snap.val()));
    }, 3000);
  }

  doStuff(d) {
    // console.log(d)
  }

  finalTripDetails(data) {
    this.riderTrip.tripstatus = '';
    this.tripvalueChanged$.next(data);
    this.tripvalueChanged$.subscribe(data => this.doStuff(data));
    // console.log(this.riderTrip.tripstatus)
    // console.log(data)
    // this.initial = 'trackingScreen';
    this.tripfinalDet = data;
    // console.log(this.tripfinalDet);
    if (data.status === '1') {
      // accepted
      this.initial = 'trackingScreen';
      this.riderTrip.tripstatus = 'Driver Accepted';
    } else if (data.status === '2') {
      // arrived
      this.initial = 'trackingScreen';
      this.riderTrip.tripstatus = 'Driver Arrived to your Pickup Location';
    } else if (data.status === '3') {
      this.initial = 'trackingScreen';
      this.riderTrip.tripstatus = 'Trip in Progress';
    } else if (data.status === '4') {
      this.riderTrip.tripstatus = 'Finished';
      this.initial = 'completedTripScreen';
      clearInterval(this.tripDataTime);
      this.tripfinalDet.distance = parseFloat(this.tripfinalDet.distance).toFixed(2);
    } else if (data.status === '5') {
      // cancelled
      this.initial = 'trackingScreen';
      this.riderTrip.tripstatus = 'Trip Cancelled';
    }
    this.feedBackDetails = {
      rating: 0
    };
  }

  sendFeedback(data) {
    const feedObj = {
      rating: data.rating,
      comments: data.comments,
      tripId: this.tripID
    };
    this.sectionService.riderFeedback(feedObj)
      .then(res => {
        this.toastr.showtoast('success', res.message);
        this.clearAllData();
      })
      .catch(response => {
        const error = JSON.parse(response._body);
        this.toastr.showtoast('error', error.message);
      });
  }

  ngOnDestroy(): void {
    clearTimeout(this.spinnerTimeout);
    this.valueChanged$.next();
    this.valueChanged$.complete();
    this.tripvalueChanged$.next();
    this.tripvalueChanged$.complete();
  }

  clearAllData() {
    this.initial = 'tripBookingScreen';
    this.list1 = {};
    this.showFareBook = false;
    this.driverNotAvailable = false;
    this.hideBook = false;
    this.showBasic = false;
    this.showVehicle = false;
    this.ToggleFare = false;
    this.NewListArr = {};
    this.NewList = {};
    this.distanceList = {};
    this.tripID = '';
    this.reqTripID = '';
    this.overallBooked = '';
    this.bookedDriverDetails = '';
    this.bookedLocationDetails = '';
    this.bookedTaxiDetails = '';
    this.tripfinalDet = '';
    this.valueChanged$ = new Subject();
    this.tripvalueChanged$ = new Subject();
    this.showMapError = '';
    this.markers = [];
    this.originPlaceId = null;
    this.destinationPlaceId = null;
    this.defaultZoom = AppSettings.ZOOM_MAP_LOCATION;
    this.defaultLocation = AppSettings.DEFAULT_LOCATION;
    this.directionsDisplay.set('directions', null);
    localStorage.removeItem('location')
    localStorage.removeItem('droplocation')
    this.initializeMap();
  }
  mapTrigger(sharedData) {
    this.map = new google.maps.Map(this.mapNativeElement.nativeElement, {
      zoom: this.defaultZoom,
      center: { lat: AppSettings.LATITUDE, lng: AppSettings.LONGITUDE },
      mapTypeId: google.maps.MapTypeId.ROADMAP
    });
    this.directionsService = new google.maps.DirectionsService();
    this.directionsDisplay =
      new google.maps.DirectionsRenderer({
        suppressMarkers: true, polylineOptions: {
          strokeColor: '#006400', strokeOpacity: 0.7,
          strokeWeight: 5
        }
      });
    this.directionsDisplay.setMap(this.map);

    /** Daily Rides */

    // this.originPlaceId = null;
    // this.destinationPlaceId = null;
    // const originInput = document.getElementById('origin-input');
    // const destinationInput = document.getElementById('destination-input');
    // const originAutocomplete = new google.maps.places.Autocomplete(originInput);
    // originAutocomplete.setFields(['place_id']);
    // const destinationAutocomplete =
    //   new google.maps.places.Autocomplete(destinationInput);
    // destinationAutocomplete.setFields(['place_id']);
    // this.setupPlaceChangedListener(originAutocomplete, 'ORIG');
    // this.setupPlaceChangedListener(destinationAutocomplete, 'DEST');
    this.originPlaceId = null;
    this.destinationPlaceId = null;
    const originAutocomplete = new google.maps.places.Autocomplete(document.getElementById('origin-input'));
    originAutocomplete.setFields(['place_id']);
    const destinationAutocomplete =
      new google.maps.places.Autocomplete(document.getElementById('destination-input'));
    destinationAutocomplete.setFields(['place_id']);
    this.setupPlaceChangedListener(originAutocomplete, 'ORIG');
    this.setupPlaceChangedListener(destinationAutocomplete, 'DEST');

    let getData = JSON.parse(sharedData)
    let DropFare = localStorage.getItem('droplocation')
    if(DropFare !== null){
      let getDropData = JSON.parse(DropFare) 
      this.list1.drop = getDropData["drop"]
      this.destinationPlaceId= getDropData["placeId"];
    }
    this.list1.pick = getData["pick"];
    this.originPlaceId = getData["placeId"];
    console.log(this.originPlaceId)
    this.setIndividualMarker[this.originPlaceId];
    const me = this;
    if(localStorage.getItem('droplocation') === null){
      this.geocoder.geocode({ 'placeId': this.originPlaceId }, function (results, status) {
        if (status === 'OK') {
          if (results[0]) {
            const leg = results[0];
            me.showMapError = '';
            me.map.setZoom(15);
            me.makeMarker(leg.geometry.location, me.markerIcon.start, leg.formatted_address);
            // me.makeMarker(leg.end_location, me.markerIcon.end, leg.end_address);
            me.map.setCenter(results[0].geometry.location);
          }
          
          else {
            this.showMapError = 'No Data Found';
            this.clearMap();
          }
        } else {
          this.showMapError = 'Geocoder failed due to: ' + status;
        }
      });
    }else{
      console.log("--------------rote")
      me.route();
    }
  }
  initializeMap() {
    this.map = new google.maps.Map(this.mapNativeElement.nativeElement, {
      zoom: this.defaultZoom,
      center: { lat: AppSettings.LATITUDE, lng: AppSettings.LONGITUDE },
      mapTypeId: google.maps.MapTypeId.ROADMAP
    });
    this.directionsService = new google.maps.DirectionsService();
    this.directionsDisplay =
      new google.maps.DirectionsRenderer({
        suppressMarkers: true, polylineOptions: {
          strokeColor: '#006400', strokeOpacity: 0.7,
          strokeWeight: 5
        }
      });
    this.directionsDisplay.setMap(this.map);

    /** Daily Rides */

    // this.originPlaceId = null;
    // this.destinationPlaceId = null;
    // const originInput = document.getElementById('origin-input');
    // const destinationInput = document.getElementById('destination-input');
    // const originAutocomplete = new google.maps.places.Autocomplete(originInput);
    // originAutocomplete.setFields(['place_id']);
    // const destinationAutocomplete =
    //   new google.maps.places.Autocomplete(destinationInput);
    // destinationAutocomplete.setFields(['place_id']);
    // this.setupPlaceChangedListener(originAutocomplete, 'ORIG');
    // this.setupPlaceChangedListener(destinationAutocomplete, 'DEST');
    this.originPlaceId = null;
    this.destinationPlaceId = null;
    const originAutocomplete = new google.maps.places.Autocomplete(document.getElementById('origin-input'));
    originAutocomplete.setFields(['place_id']);
    const destinationAutocomplete =
      new google.maps.places.Autocomplete(document.getElementById('destination-input'));
    destinationAutocomplete.setFields(['place_id']);
    this.setupPlaceChangedListener(originAutocomplete, 'ORIG');
    this.setupPlaceChangedListener(destinationAutocomplete, 'DEST');
  }

  setupPlaceChangedListener(autocomplete, mode) {
    const me = this;
    autocomplete.addListener('place_changed', function () {
      me.markers.forEach(function (marker) {
        marker.setMap(null);
      });
      me.markers = [];
      const place = autocomplete.getPlace();
      if (!place.place_id) {
        this.showMapError = 'Please Enter Correct Location.';
        return;
      }
      if (mode === 'ORIG') {
        me.originPlaceId = place.place_id;
        me.setIndividualMarker(place.place_id);
      } else {
        me.destinationPlaceId = place.place_id;
        me.setIndividualMarker(place.place_id);
      }
      me.route();
    });
  }

  setIndividualMarker(request) {
    console.log(request,"===========REQUEST")
    const me = this;
    me.geocoder.geocode({ 'placeId': request }, function (results, status) {
      if (status === 'OK') {
        console.log(status,"================>")
        if (results[0]) {
          const leg = results[0];
          me.showMapError = '';
          me.map.setZoom(15);
          me.makeMarker(leg.geometry.location, me.markerIcon.start, leg.formatted_address);
          me.map.setCenter(results[0].geometry.location);
        } else {
          me.showMapError = 'No Data Found';
          me.clearMap();
        }
      } else {
        me.showMapError = 'Geocoder failed due to: ' + status;
      }
    });
  }

  route() {
    if (!this.originPlaceId || !this.destinationPlaceId) {
      return;
    }
    const me = this;
    me.directionsService.route({
      origin: { 'placeId': me.originPlaceId },
      destination: { 'placeId': me.destinationPlaceId },
      travelMode: 'DRIVING'
    }, (response, status) => {
      if (status === 'OK') {
        me.showVehicle = false;
        me.directionsDisplay.setDirections(response);
        const leg = response.routes[0].legs[0];
        me.list1 = {
          pick: leg.start_address,
          pickupLat: leg.start_location.lat(),
          pickupLng: leg.start_location.lng(),
          dropLat: leg.end_location.lat(),
          dropLng: leg.end_location.lng(),
          drop: leg.end_address
        };
        // me.getFareEstimation();
        me.showMapError = '';
        me.markers.forEach(function (marker) {
          marker.setMap(null);
        });
        me.markers = [];
        me.makeMarker(leg.start_location, me.markerIcon.start, leg.start_address);
        me.makeMarker(leg.end_location, me.markerIcon.end, leg.end_address);
      } else {
        me.showMapError = 'Directions request failed due to ' + status;
        me.clearMap();
      }
    });
  }

  makeMarker(position, icon, title) {
    this.markers.push(new google.maps.Marker({
      position: position,
      animation: google.maps.Animation.DROP,
      icon: icon,
      map: this.map,
      draggable: false,
      title: title
    }));
  }

  clearMap() {
    this.directionsDisplay.set('directions', null);
    this.map = new google.maps.Map(this.mapNativeElement.nativeElement, {
      zoom: this.defaultZoom,
      center: { lat: AppSettings.LATITUDE, lng: AppSettings.LONGITUDE },
      mapTypeId: google.maps.MapTypeId.ROADMAP
    });
    this.directionsDisplay.setMap(this.map);
  }

  routeToMyTrips() {
    this.router.navigate(['ridertrips'], {
      queryParams: {
        tripId: this.reqTripID
      },
      queryParamsHandling: 'merge',
    });
  }
  from = {
    lat: 0,
    lng: 0,
  };
  to = {
    lat: 0,
    lng: 0,
  };
  placeChangedP(place) {
    console.log(place,"===============>")
    this.list1.pick = place.formatted_address;
    this.list1.pickupLat = this.from.lat;
    this.list1.pickupLng = this.from.lat;
    this.list1.placeId = place.place_id;
    // this.list1.Type = this.tripType
    localStorage.setItem('location', JSON.stringify(this.list1))
    // this.locationservice.setLocationInfo(JSON.stringify(this.list1))
  }
  dropplaceChangedP(place) {
    this.list1.drop = place.formatted_address;
    this.list1.placeId = place.place_id;
    localStorage.setItem('droplocation', JSON.stringify(this.list1))
  }
}
